<template>
  <router-view />
</template>
<script>

import { onUnmounted } from '@vue/composition-api'
import organizationModule from '@/store/organization'
import store from '@/store'

export default {
  name: 'Organization',
  created() {
    this.$emit('updateMenu', 'settings-general-organization')
  },
  setup() {
    const MODULE_NAME = 'organization'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, organizationModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
    }
  },
}
</script>

<style scoped>

</style>
