export default {
  GET(state, data) {
    state.organization = { ...data }
  },
  LIST(state, data) {
    state.organizations = data
  },
  SET_ORGANIZATION_FORM(state, data) {
    state.organizationForm = { ...data }
  },
}
